import { ChevronDownIcon } from "@heroicons/react/24/outline";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import clsx from "clsx";
import type { UseComboboxGetItemPropsOptions, UseComboboxProps, UseMultipleSelectionGetDropdownProps, UseMultipleSelectionReturnValue } from "downshift";
import { useMultipleSelection } from "downshift";
import { useCombobox } from "downshift";
import { uniqBy } from "lodash";
import type { ComponentProps, ComponentPropsWithoutRef } from "react";
import { useImperativeHandle, useRef } from "react";
import { useEffect } from "react";
import React, { forwardRef, useContext } from "react";
import { ComboBoxContext } from "./context";
import { MultiOption } from "./types";
function useComboBoxContext() {
  const context = useContext(ComboBoxContext);
  if (context === null) {
    throw new Error("ComboBoxContext is not set");
  }
  return context;
}
const {
  InputKeyDownEnter,
  ItemClick,
  InputChange,
  InputBlur,
  InputFocus
} = useCombobox.stateChangeTypes;
export const ComboBox = {
  Root: forwardRef(function ComboBoxRoot<T extends MultiOption>({
    className,
    filteredItems,
    onSelectItem,
    isError = false,
    disabled = false,
    unstyled,
    inputValue,
    setInputValue,
    isOpen,
    onIsOpenChange,
    ...props
  }: ComponentProps<"div"> & {
    filteredItems: T[];
    disabled?: boolean;
    isError?: boolean;
    unstyled?: boolean;
    inputValue: string;
    setInputValue: (value: string) => void;
    onSelectItem: (items: T) => void;
    isOpen: UseComboboxProps<T>["isOpen"];
    onIsOpenChange: UseComboboxProps<T>["onIsOpenChange"];
  }, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
    const combobox = useCombobox<T>({
      inputValue,
      selectedItem: null,
      isOpen: disabled ? false : isOpen,
      onIsOpenChange,
      items: filteredItems,
      stateReducer: (_state, actionAndChanges) => {
        const {
          changes,
          type
        } = actionAndChanges;
        switch (type) {
          // Regression in downshift https://github.com/downshift-js/downshift/issues/1439
          case InputFocus:
            return {
              ...changes,
              isOpen: _state.isOpen
            };
          case InputBlur:
            {
              if (actionAndChanges.selectItem) return {
                ...changes,
                isOpen: true
              };
              return changes;
            }
          case InputKeyDownEnter:
          case ItemClick:
            return {
              ...changes,
              isOpen: true // keep the menu open after selection.
            };
        }
        return changes;
      },
      onStateChange: ({
        inputValue,
        type,
        selectedItem
      }) => {
        switch (type) {
          case InputChange:
            setInputValue(inputValue || "");
            combobox.setHighlightedIndex(inputValue === "" || inputValue === undefined ? -1 : 0);
            break;
          case InputKeyDownEnter:
          case ItemClick:
            if (selectedItem) {
              onSelectItem(selectedItem);
              setInputValue("");
            }
            break;
          case InputBlur:
            setInputValue("");
            break;
          default:
            break;
        }
      }
    });
    return <ComboBoxContext.Provider value={{
      ...combobox,
      disabled,
      filteredItems
    }}>
        <PopoverPrimitive.Root open={combobox.isOpen}>
          <div {...props} ref={forwardedRef} className={clsx(!unstyled && ["flex w-full flex-col divide-y disabled:cursor-not-allowed", "data-[locked=true]:bg-neutral-50", isError ? "border-error" : [combobox.isOpen ? "h-full" : "h-10 overflow-hidden"], disabled && "cursor-not-allowed"], className)} />
        </PopoverPrimitive.Root>
      </ComboBoxContext.Provider>;
  }),
  Label: ({
    className,
    ...props
  }: ComponentProps<"label">) => {
    const {
      getLabelProps
    } = useComboBoxContext();
    return <label {...getLabelProps()} className={clsx("sr-only", className)} {...props} />;
  },
  Trigger: forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<"div"> & {
    chevronIconClassName?: string;
  }>(function ComboBoxTrigger({
    className,
    children,
    chevronIconClassName,
    ...props
  }, forwardedRef) {
    const {
      getToggleButtonProps,
      disabled,
      isOpen
    } = useComboBoxContext();
    return <PopoverPrimitive.Anchor {...getToggleButtonProps({
      ref: forwardedRef
    })} aria-label="toggle menu" tabIndex="-1" {...props} className={clsx("relative h-10 pl-2 pr-8 py-1", disabled ? "bg-neutral-100" : "bg-transparent", className)}>
        {children}
        <ChevronDownIcon className={clsx("absolute right-1 top-1/2 -translate-y-1/2 h-6 w-6 shrink-0 text-cherry-red-60 duration-200", isOpen && "rotate-180 transition-transform  ease-in-out", !isOpen && "transition-transform  ease-in-out", chevronIconClassName)} />
      </PopoverPrimitive.Anchor>;
  }),
  TriggerWithoutChevron: forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<"div">>(function ComboBoxTrigger({
    ...props
  }, forwardedRef) {
    const {
      getToggleButtonProps
    } = useComboBoxContext();
    return <PopoverPrimitive.Anchor {...getToggleButtonProps({
      ref: forwardedRef
    })} aria-label="toggle menu" tabIndex="-1" {...props}
    // className={clsx("relative h-full pl-2 pr-8 py-1", disabled ? "bg-neutral-100" : "bg-white", className)}
    />;
  }),
  Portal: PopoverPrimitive.Portal,
  Menu: forwardRef<HTMLUListElement, ComponentProps<"div">>(function ComboBoxContent({
    className,
    ...props
  }, forwardedRef) {
    const {
      getMenuProps,
      isOpen
    } = useComboBoxContext();
    return <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content sideOffset={1} asChild
      // These are taken care by downshift
      onOpenAutoFocus={e => e.preventDefault()} onCloseAutoFocus={e => e.preventDefault()} onEscapeKeyDown={e => e.preventDefault()} onPointerDownOutside={e => e.preventDefault()} onFocusOutside={e => e.preventDefault()} onInteractOutside={e => e.preventDefault()} {...props} {...getMenuProps({
        ref: forwardedRef
      }, {
        suppressRefError: true
      })}
      // We do this -mx-px and +2px to account for the border on the trigger
      className={clsx("-mx-px max-h-[40vh] overflow-y-auto px-4 py-4 scroll-smooth border border-t-0 bg-white", isOpen ? "flex flex-col" : "hidden", className)} style={{
        width: "calc(var(--radix-popper-anchor-width) + 2px)"
      }}>
          <ul>{props.children}</ul>
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>;
  }),
  Value: forwardRef<HTMLDivElement, ComponentProps<"div">>(function ComboBoxValue({
    className,
    ...props
  }, forwardedRef) {
    return <div {...props} ref={forwardedRef} className={clsx(className)} />;
  }),
  Input: ({
    className,
    getDropdownProps,
    ...props
  }: UseMultipleSelectionGetDropdownProps & {
    getDropdownProps?: UseMultipleSelectionReturnValue<unknown>["getDropdownProps"];
  }) => {
    const {
      getInputProps,
      disabled
    } = useComboBoxContext();
    return <input {...getInputProps(getDropdownProps ? getDropdownProps(props) : props)} className={clsx(className, "w-full border-none focus:outline-none focus:ring-0 disabled:cursor-not-allowed bg-transparent h-max p-0 placeholder:text-neutral-600 text-note")} disabled={disabled} />;
  },
  TableInput: ({
    className,
    getDropdownProps,
    ...props
  }: UseMultipleSelectionGetDropdownProps & {
    getDropdownProps?: UseMultipleSelectionReturnValue<unknown>["getDropdownProps"];
  }) => {
    const {
      getInputProps,
      disabled
    } = useComboBoxContext();
    return <input {...getInputProps(getDropdownProps ? getDropdownProps(props) : props)} className={clsx(className, "text-neutral-800 placeholder:text-neutral-600 text-note")} disabled={disabled} />;
  },
  Option: forwardRef(function ComboBoxOption<T extends MultiOption>({
    className,
    index,
    item,
    isSelected,
    disabled,
    ...props
  }: ComponentPropsWithoutRef<"div"> & UseComboboxGetItemPropsOptions<T>, forwardedRef: React.ForwardedRef<HTMLLIElement>) {
    const innerRef = useRef<HTMLLIElement>(null);
    useImperativeHandle(forwardedRef, () => innerRef.current as HTMLLIElement);
    const {
      getItemProps,
      highlightedIndex
    } = useComboBoxContext();
    // This is a workaround for the fact that downshift for some reason does not scroll the highlighted item into view
    useEffect(() => {
      if (highlightedIndex === index) {
        const el = innerRef.current;
        if (el) {
          el.scrollIntoView({
            block: "nearest"
          });
        }
      }
    }, [highlightedIndex, index]);
    return <li {...getItemProps({
      ref: innerRef,
      index,
      item,
      disabled
    })} {...props} aria-current={highlightedIndex === index} data-selected={isSelected} className={clsx("relative flex cursor-pointer", index === 0 && "scroll-m-8", highlightedIndex === index && "bg-neutral-100", className)} />;
  }),
  OptionLabel: ({
    className,
    ...props
  }: ComponentProps<"label">) => {
    const {
      filteredItems
    } = useComboBoxContext();
    const content = filteredItems.length > 0 ? "Choisir une option:" : "Aucune option disponible";
    return <label className={clsx("sr-only", className)} {...props}>
        {content}
      </label>;
  }
};
const {
  FunctionAddSelectedItem,
  FunctionRemoveSelectedItem
} = useMultipleSelection.stateChangeTypes;
export function useComboboxSelection<T extends MultiOption>({
  selectedItems,
  onValueChange,
  uniqueById = true,
  multiple = true
}: {
  selectedItems: T[];
  onValueChange: (data: T[]) => void;
  uniqueById?: boolean;
  multiple?: boolean;
}): UseMultipleSelectionReturnValue<T> {
  const selection = useMultipleSelection<T>({
    selectedItems,
    onSelectedItemsChange: ({
      selectedItems
    }) => {
      onValueChange(selectedItems || []);
    },
    stateReducer: (_state, actionAndChanges) => {
      const {
        changes,
        type
      } = actionAndChanges;
      switch (type) {
        case FunctionAddSelectedItem:
          {
            if (!multiple) {
              return {
                ...changes,
                selectedItems: changes.selectedItems?.slice(-1)
              };
            }
            if (uniqueById) {
              return {
                ...changes,
                selectedItems: uniqBy<T>(changes.selectedItems, "id")
              };
            }
            return {
              ...changes,
              selectedItems: changes.selectedItems
            };
          }
        case FunctionRemoveSelectedItem:
          {
            return {
              ...changes,
              selectedItems: changes.selectedItems
            };
          }
      }
      return changes;
    }
  });
  return selection;
}