import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useMemo, useState } from "react";
import type { MultiOption } from "../ComboBox/types";
import { useComboboxSelection } from "../ComboBox/useComboboxSelection";
import { getOptionsItems } from "../ComboBox/utils";
import Typography from "../Typography";
import { Badge } from "../badge";
import { Checkbox } from "../inputs/checkBox";
import { ComboBox } from "./index";
export type Props<Option extends MultiOption> = {
  readonly searchKeys?: ReadonlyArray<"label" | "value">;
  readonly readOnly?: boolean;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly options: Option[];
  readonly isError?: boolean;
  readonly value: Option[];
  readonly isMultipleChoice?: boolean;
  readonly className?: string;
  readonly chevronClassName?: string;
  readonly onChange: (value: Option[]) => void;
};
export function SearchableSelect<Option extends MultiOption>({
  options,
  placeholder = "Sélectionner...",
  searchKeys = ["label"],
  isError,
  value = [],
  onChange,
  disabled,
  className,
  chevronClassName,
  isMultipleChoice = false,
  readOnly
}: Props<Option>) {
  const isFieldDisabled = disabled || readOnly;
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);
  const filteredOptionItems = useMemo(() => getOptionsItems({
    optionItems: options,
    inputValue,
    keys: searchKeys
  }), [options, inputValue, searchKeys]);
  const {
    selectedItems,
    setSelectedItems,
    addSelectedItem,
    removeSelectedItem,
    getSelectedItemProps,
    getDropdownProps
  } = useComboboxSelection<Option>({
    selectedItems: value,
    onValueChange: onChange,
    multiple: isMultipleChoice
  });
  const hasSelectedItems = selectedItems.length > 0;
  const firstItem = hasSelectedItems ? selectedItems[0] : undefined;
  const remainingCount = Math.max(0, selectedItems.length - 1);
  return <div className="w-full" data-sentry-component="SearchableSelect" data-sentry-source-file="SearchableSelect.tsx">
      <ComboBox.Root isOpen={isOpen} onIsOpenChange={change => setIsOpen(change.isOpen)} disabled={isFieldDisabled} isError={isError} filteredItems={filteredOptionItems} inputValue={inputValue} setInputValue={setInputValue} onSelectItem={selectedOption => {
      const foundItem = selectedItems.find(item => item.id === selectedOption.id);
      if (foundItem) {
        removeSelectedItem(foundItem);
      } else {
        if (isMultipleChoice) {
          addSelectedItem(selectedOption as Option);
        } else {
          setSelectedItems([selectedOption as Option]);
          setIsOpen(false);
        }
      }
    }} className={clsx("relative w-[200px]", isError && "border border-error", className)} data-sentry-element="unknown" data-sentry-source-file="SearchableSelect.tsx">
        <ComboBox.Trigger chevronIconClassName={chevronClassName} className="flex items-center gap-1 p-1 rounded-[3px]" data-sentry-element="unknown" data-sentry-source-file="SearchableSelect.tsx">
          <ComboBox.Label className="sr-only" data-sentry-element="unknown" data-sentry-source-file="SearchableSelect.tsx">Choisir une option:</ComboBox.Label>

          {/* If we have selected items, show the first one plus any remainder count.
              If none is selected, show placeholder text. */}
          {hasSelectedItems && !isOpen ? <>
              <Badge onClose={isFieldDisabled || !isMultipleChoice ? undefined : event => {
            event.stopPropagation();
            removeSelectedItem(firstItem as Option);
          }} {...firstItem ? getSelectedItemProps({
            selectedItem: firstItem,
            index: 0
          }) : {}} className={clsx(isMultipleChoice ? "rounded-full bg-sky-blue text-white" : "text-neutral-800")}>
                {firstItem?.label}
              </Badge>
              {remainingCount > 0 && <Badge className="bg-sky-blue text-white w-full h-full">+ {remainingCount}</Badge>}
            </> : <Typography.note className={isOpen ? "hidden" : "block"}>{placeholder}</Typography.note>}
          <div className={clsx("flex items-center justify-between flex-1", !isOpen && "hidden")}>
            <MagnifyingGlassIcon className="h-5 w-5 text-neutral-600 stroke-2" data-sentry-element="MagnifyingGlassIcon" data-sentry-source-file="SearchableSelect.tsx" />
            <ComboBox.Input getDropdownProps={getDropdownProps} placeholder="Rechercher..." className="ml-2 flex-1 min-w-[60px] border-none bg-transparent text-note placeholder:text-neutral-600 focus:outline-none focus:ring-0" data-sentry-element="unknown" data-sentry-source-file="SearchableSelect.tsx" />
          </div>
        </ComboBox.Trigger>

        <ComboBox.Menu data-testid="dropdown-select" className="space-y-px py-2" data-sentry-element="unknown" data-sentry-source-file="SearchableSelect.tsx">
          <ComboBox.OptionLabel className="px-3 py-1" data-sentry-element="unknown" data-sentry-source-file="SearchableSelect.tsx" />
          {filteredOptionItems.map((option, index) => {
          const isSelected = selectedItems.some(selectedItem => selectedItem.id === option.id);
          return <ComboBox.Option key={option.id} index={index} item={option} isSelected={isSelected} className="flex items-center py-1 pl-2 pr-10">
                <Checkbox readOnly rounded={!isMultipleChoice} className="mr-2 peer border-aubergine" checked={isSelected} />
                <Typography.note textColor={isMultipleChoice ? "peer-checked:text-neutral-900 text-neutral-700" : "text-neutral-800"}>
                  {option.label}
                </Typography.note>
              </ComboBox.Option>;
        })}
        </ComboBox.Menu>
      </ComboBox.Root>

      {isError && <p className="mt-1 text-sm text-error">Une erreur est survenue.</p>}
    </div>;
}